/* mini desktop version */
@media (min-width: 992px) and (max-width: 1280px) {

    /*Header section*/
    #header {
        width: 100%;
    }

    .inner-container {
        width: 100%;
    }

    /* about section */
    #about {
        width: 100%;
    }

    .inner-container {
        width: 100%;
    }

    /* Projects */
    #projects {
        width: 100%;
    }

    .projects-container {
        width: 100%;
    }

    .slider-image img {
        left: 25px;
    }

    /* Services */
    #sevices-container {
        width: 100%;
    }

    .about-inner-div {
        width: 100%;
    }

    /* team section */
    #team {
        width: 100%;
    }

    .team-container {
        width: 100%;
    }

    /* Contact section */
    #contact {
        width: 100%;
    }

    .inner-contact-container {
        width: 100%;
    }
}

/* tablet version */
@media (min-width: 768px) and (max-width: 992px) {

    /* Navigation */
    .links {
        left: 10px;
    }

    /*Header section*/
    #header {
        width: 100%;
    }

    .inner-container {
        width: 100%;
    }

    .inner-container {
        width: 100%;
    }

    .left-container {
        width: 100%;
        float: none;
    }

    .header-text p {
        line-height: 1.6em;
        font-size: 20px;
    }

    /* about section */
    #about {
        width: 100%;
    }

    .inner-container {
        width: 100%;
    }

    .left-about {
        width: 100%;
        float: none;
    }

    .left-container-image {
        width: 100%;
    }

    .left-container-image img {
        width: 80%;
    }

    .right-about {
        width: 100%;
        float: none;
    }

    .right-container-text {
        width: 100%;
    }

    .right-container-text-heading {
        width: 100%;
    }

    .right-container-text-heading h2 {
        text-align: center;
    }

    .right-container-text-text {
        width: 100%;
    }

    .right-container-text-text p {
        text-align: center;
    }

    /* Projects */
    #projects {
        width: 100%;
    }

    .projects-container {
        width: 100%;
    }

    .slider-image img {
        width: 100%;
        left: 10px;
    }

    /* Services */
    #sevices-container {
        width: 100%;
        padding-left: 0;
        padding-right: 0;
    }

    .about-inner-div {
        width: 100%;
        padding-left: 0;
        padding-right: 0;
    }

    .services {
        width: 100%;
    }

    .tabs {
        width: 100% !important;
    }

    .tabs-images .heading {
        line-height: 1.0;
    }

    .tabs button {
        width: 100% !important;
        word-wrap: break-word !important;
    }

    .tabs-images {
        text-align: center;
    }

    .tabs-images img {
        text-align: center;
        width: 100%;
    }

    .tabs-images img {
        left: 0;
    }

    /* team section */
    #team {
        width: 100%;
    }

    .team-container {
        width: 100%;
    }

    .teamimage-container {
        width: 100%;
    }

    .inline-div {
        width: 100%;
        display: block;
        text-align: center;
        margin: 0;
        margin-bottom: 50px;
    }

    .inline-div img {
        width: 100%;
        padding-bottom: 20px;
    }

    /* Contact section */
    #contact {
        width: 100%;
    }

    .inner-contact-container {
        width: 100%;
        padding-left: 10px;
        padding-right: 10px;
        padding-top: 20px;
    }

    .left-container {
        width: 100%;
        float: none;
        padding-left: 10px;
        padding-right: 10px;
    }

    .right-container {
        width: 100%;
        float: none;
        padding-left: 10px;
        padding-right: 10px;
    }

    form {
        width: 100% !important;
    }

    .contactForm {
        width: 100% !important;
    }

    .submit-btn {
        border: none !important;
    }
}

/* mobile version */
@media only screen and (max-width: 767px) {

    /* Navigation */
    .links {
        left: 10px;
    }

    /*Header section*/
    #header {
        width: 100%;
        background-position: right bottom;
    }

    .inner-container {
        width: 100%;
    }

    .inner-container {
        width: 100%;
    }

    .left-container {
        width: 100%;
        float: none;
    }

    .header-heading h1 {
        font-size: 45px;
    }

    .header-text p {
        line-height: 1.6em;
        font-size: 20px;
    }

    .header-btn {
        width: 60%;
    }

    /* about section */
    #about {
        width: 100%;
    }

    .inner-container {
        width: 100%;
    }

    .left-about {
        width: 100%;
        float: none;
    }

    .left-container-image {
        width: 100%;
    }

    .left-container-image img {
        width: 80%;
    }

    .right-about {
        width: 100%;
        float: none;
    }

    .right-container-text {
        width: 100%;
    }

    .right-container-text-heading {
        width: 100%;
    }

    .right-container-text-heading h2 {
        text-align: center;
    }

    .right-container-text-text {
        width: 100%;
    }

    .right-container-text-text p {
        text-align: center;
    }

    /* Projects */
    #projects {
        width: 100%;
    }

    .projects-container {
        width: 100%;
    }

    .slider-image img {
        width: 100%;
        left: 10px;
    }

    /* Services section */
    #sevices-container {
        width: 100%;
        padding-left: 0;
        padding-right: 0;
        padding-top: 10px;
    }

    .about-inner-div {
        width: 100%;
        padding-left: 0;
        padding-right: 0;
    }

    .tabs {
        display: flex;
        flex-direction: column;
        align-items: stretch;
    }

    .tabs button {
        width: 100%;
        padding: 10px; /* Add padding to the buttons for better spacing */
        margin-bottom: 10px; /* Add margin to separate the buttons */
        word-wrap: break-word !important;
    }

    .tabs-images {
        text-align: center;
    }

    .tabs-images img {
        text-align: center;
        width: 100%;
    }

    .tabs-images img {
        left: 0;
    }

    .content {
        padding: 50px 5px 80px 5px !important; /* Add padding to the content for better spacing */
    }

    .content p{
        font-size: 14px;
    }


    /*.tabs {
        width: 100% !important;
    }

    .tabs-images .heading {
        line-height: 1.0;
    }

    .tabs button {
        width: 100% !important;
        padding-left: 5px !important;
        padding-right: 5px !important;
        word-wrap: break-word !important;
    }

    .tabs-images {
        text-align: center;
    }

    .tabs-images img {
        text-align: center;
        width: 100%;
    }

    .tabs-images img {
        left: 0;
    }
    */

    /* team section */
    #team {
        width: 100%;
    }

    .team-container {
        width: 100%;
    }

    .teamimage-container {
        width: 100%;
    }

    .inline-div {
        width: 100%;
        display: block;
        text-align: center;
        margin: 0;
        margin-bottom: 50px;
    }

    .inline-div img {
        width: 100%;
        padding-bottom: 20px;
    }


    /* Contact */
    #contact {
        width: 100%;
    }

    .inner-contact-container {
        width: 100%;
        padding-left: 10px;
        padding-right: 10px;
        padding-top: 20px;
    }

    .left-container {
        width: 100%;
        float: none;
        padding-left: 10px;
        padding-right: 10px;
    }

    .right-container {
        width: 100%;
        float: none;
        padding-left: 10px;
        padding-right: 10px;
    }

    .contact-text {
        width: 100%;
    }

    form {
        width: 100% !important;
    }

    .contactForm {
        width: 100% !important;
    }

    .submit-btn {
        border: none !important;
    }
}