.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}


/* Custom css */
/**************/
/****** Css ******/
/* custom scroll bar */
::-webkit-scrollbar {
  width: 5px;
}

/* Track */
::-webkit-scrollbar-track {
  box-shadow: inset 0 0 5px grey; 
  border-radius: 10px;
}
 
/* Handle */
::-webkit-scrollbar-thumb {
  background: #9e72fc; 
  border-radius: 5px;
}

/* Font css */
@font-face {
	font-family: PoppinsBold;
	src: url("../src/fonts/Poppins-Bold.ttf");
}
@font-face {
	font-family: Poppinsitalic;
	src: url("../src/fonts/Poppins-ExtraLightItalic.ttf");
}
@font-face {
	font-family: Poppinsregular;
	src: url("../src/fonts/Poppins-Regular.ttf");
}

.body{
  width: 100%;
  height: auto;
  overflow-x: hidden;
}

/* body  bg */
.body-bg{
  width: 100%;
  height: auto;
  background: url("../src/assets/bg.png");
  background-repeat: no-repeat;
  background-size: cover;
}

/* navigation */
#logo-heading{
  /*width: 100%;*/
  width: 100px;
  position: relative;
  left: 8.5%;
}
#logo-heading img{
  width: 100px;
}
.nav-items{
  color: white !important;
  padding-right: 40px !important;
  font-family: PoppinsBold !important;
}
.navbarBg{
  width: 100%;
}
.navbar-collapse{
  background: transparent !important;
  padding: 20px !important;
}
.links{
  position: relative;
  top: -5px;
  left: 55%;
  font-size: 15px;
  font-weight: bold;
  line-height: 24px;
  font-family: PoppinsBold !important;
}
.show{
  background-color: black !important;
}
.mbl-title{
  color: white !important;
}
.btn-close{
  color: white !important;
}
#close{
  color: white !important;
}

.offcanvas-header .btn-close{
  background: url("../src/assets/close.png");
  background-size: contain;
}

.navbar-toggler-icon{
  background: url("../src/assets/bar.png") !important;
  background-size: 100% 100% !important;
}

/*Header css*/
#header{
  width: 100%;
  height: 100vh;
  background-image: url("../src/assets/banner.png");
  background-repeat: no-repeat;
  background-size: cover;
  background-position: bottom right;
  padding: 10px;
}
.inner-container{
  width: 100%;
  text-align: center;
  padding: 10px;
}
.left-container{
  width: 40%;
  float: left;
  padding: 5px;
  padding-top: 50px;
}
.header-heading h1{
  color: white;
  text-align: left;
  font-family: PoppinsBold;
  font-size: 65px;
}
.header-text p{
  color: white;
  text-align: left;
  padding-right: 70px;
  padding-top: 10px;
  font-family: Poppinsitalic;
  line-height: 1.6em;
  letter-spacing: 1px;
}
.header-btn{
  width: 40%;
  text-align: left;
  background: url("../src/assets/header-btn.png");
  background-size: 100% 100%;
  background-repeat: no-repeat;
  padding: 14px 10px 5px 10px;
  position: relative;
  top: 20px;
  transition: transform .2s;
}
.header-btn:hover{
  transition: transform .2s;
  transform: scale(1.1);
}
.header-btn a, h2{
  text-decoration: none;
  color: black;
  text-align: center;
  font-size: 18px !important;
  font-weight: bold !important;
  font-family: Poppinsregular;
}
.right-container{
  width: 60%;
  float: right;
  padding: 5px;
}

/* about section */
#about{
  width: 100%;
  padding-top: 30px;
  margin-top: -5px;
}
.inner-container{
  width: 100%;
  padding: 10px;
  padding-top: 50px !important;
}
.left-about{
  width: 50%;
  float: left;
  padding: 5px;
}
.left-container-image{
  width: 100%;
}
.left-container-image img{
  width: 80%;
}

.right-about{
  width: 50%;
  float: right;
  padding: 5px;
}
.right-container-text{
  width: 100%;
  /*padding-top: 50px;*/
}
.right-container-text-heading{
  width: 100%;
  text-align: center;
  /*padding-top: 50px;*/
}
.right-container-text-heading h2{
  text-align: left;
  color: white;
  font-family: PoppinsBold;
  padding-top: 10px;
  font-size: 40px !important;
}
.right-container-text-text{
  width: 100%;
  padding-top: 50px;
}
.right-container-text-text p{
  color: white;
  font-family: Poppinsregular;
  line-height: 1.6em;
  letter-spacing: 2px;
  text-align: left;
}

/* Projects */
#projects{
  width: 100%;
  height: auto;
  padding-top: 50px;
}
.projects-container{
  width: 100%;
}
.projects-heading{
  text-align: center;
  padding-top: 20px;
  padding-bottom: 50px;
}
.projects-heading h1{
  color: white;
  font-size: 40px;
  font-weight: bold;
  font-family: PoppinsBold;
}
.sldier-carousel{
  text-align: center;
}
.slider-image img{
  width: 80%;
  position: relative;
  left: 40px;
}

/* Services */
#sevices-container{
  width: 100%;
  height: auto;
  padding-top: 30px;
}
.about-inner-div{
  width: 100%;
  padding: 10px 20px 10px 20px;
}
.services-heading{
  width: 100%;
  text-align: center;
  padding-top: 80px;
  padding-bottom: 60px;
}
.services-heading h1{
  color: white;
  font-size: 40px !important;
  font-weight: bold;
  font-family: PoppinsBold;
}
.services-content{
  width: 100%;
  padding: 10px;
}
.services-content p{
  text-align: left;
  color: white;
  font-family: Poppinsregular;
}

.services{
  width: 100%;
  text-align: center;
  padding-top: 50px;
}
.tabs-images{
  text-align: center;
}
.tabs-images .client-slider img{
  text-align: center;
  left: 25%;
}
.tabs-images a{
  text-align: center !important;
  color: white !important;
  font-size: 18px !important;
  font-weight: bold;
  position: relative !important;
  top: 20px !important;
  font-family: Poppinsregular;
  font-size: 20px;
}
.tabs-images a p:hover{
  
}
.tabs-images p{
  text-align: center;
  color: white;
  padding-left: 30px;
  padding-right: 30px;
  font-family: Poppinsregular;
  font-size: 20px;
}
.tabs-images .heading{
  font-size: 40px;
  padding-bottom: 10px;
}

.tabs {
  display: flex !important;
  justify-content: space-between !important;
  border-top-left-radius: 20px !important;
  border-top-right-radius: 20px !important;
}

.tabs button {
  border: none !important;
  color: white !important;
  cursor: pointer !important;
  padding: 25px !important;
  width: 100% !important;
  word-wrap: break-word !important;
  background: url("../src/assets/service_button.png");
  background-size: 100% 100%;
  background-repeat: no-repeat;
  transition: all 0.5s ease-out !important;
  z-index: 10 !important;
  font-size: 16px !important;
}

.tabs button:hover {
  color: white !important;
  background: url("../src/assets/hover_button.png");
  background-size: 100% 100%;
  background-repeat: no-repeat;
  z-index: 10 !important;
}

.tabs button:disabled {
  background: url("../src/assets/hover_button.png");
  background-size: 100% 100%;
  background-repeat: no-repeat;
  color: white !important;
}

.title {
  color: white !important;
  font-size: medium !important;
  margin-bottom: 20px !important;
  font-size: 20px !important;
  font-weight: 600 !important;
  font-family: Poppinsregular;
  font-size: 18px !important;
}

.content {
  color: white !important;
  padding: 50px 80px !important;
  font-family: Poppinsregular;
  font-weight: 300 !important;
  line-height: 30px !important;
  font-size: 16px !important;
  text-align: justify !important;
  border-bottom-left-radius: 20px !important;
  border-bottom-right-radius: 20px !important;
  font-size: 18px !important;
}
.content,
button {
  font-size: 15px !important;
  font-weight: medium !important;
  font-family: Poppinsregular;
  font-size: 18px !important;
}

.tabs-images{
  text-align: center;
}
.tabs-images img{
  text-align: center;
  width: 100%;
  object-fit: contain;

}

@media only screen and (max-width: 960px) {

  .container {
      width: 100% !important;
  }

  .content {
      padding: 50px !important;
  }
}

/* team section */
#team{
  width: 100%;
  height: auto;
}
.team-container{
  width: 100%;
}
.team-heading{
  text-align: center;
  padding-top: 20px;
  padding-bottom: 50px;
}
.team-heading h1{
  color: white;
  font-size: 40px;
  font-weight: bold;
  font-family: PoppinsBold;
}
.teamimage-container{
  width: 100%;
  text-align: center;
}
.inline-div{
  width:calc(25% - 10px);
  margin:0 20px;
  text-align:center;
  height: auto;
  background-color: #150D42;
  display: inline-block;
  border: 2px solid white;
  border-radius: 2px;
}
.inline-div img{
  width: 100%;
  height: auto;
}
.inline-div h5{
  color: #b2a623;
  text-align: left;
  font-size: 14px;
  padding-top: 5px;
  padding-left: 10px;
  font-family: Poppinsregular;
}
.inline-div p{
  color: white;
  text-align: left;
  font-size: 12px;
  padding-left: 10px;
  padding-right: 2px;
  font-family: Poppinsregular;
}

/* Contact section */
#contact{
  width: 100%;
  height: auto;
}
.inner-contact-container{
  width: 100%;
  text-align: center;
  padding: 70px 50px 30px 50px;
}
.contact-heading{
  text-align: center;
  padding-top: 20px;
  padding-bottom: 50px;
}
.contact-heading h1{
  color: white;
  font-size: 40px;
  font-weight: bold;
  font-family: PoppinsBold;
}
.social-container1{
  text-align: center;
  margin-top: 20px;
}
.left-container{
  width: 50%;
  float: left;
  padding: 40px 50px 40px 10px;
}
.right-container{
  width: 50%;
  float: right;
  padding: 40px 10px 40px 10px;
}
.contact-heading{
  width: 100%;
}
.contact-heading h2{
  text-align: left;
  color: white;
  font-family: PoppinsBold;
}
.contact-text{
  width: 100%;
}
.contact-text p{
  text-align: left;
  color: white;
  font-family: Poppinsregular;
  font-size: 18px;
}
.contact-lowerleft-container{
  width: 100%;
}
.left-text {
  text-align: left;
  margin-bottom: 10px;
}
.left-text a{
  color: white;
  text-align: left;
  font-family: Poppinsregular;
  font-size: 18px;
}
.left-text a:hover{
  color: #9e72fc;
}
.left-text h2{
  padding-top: 20px;
  color: white;
  text-align: left;
  font-family: PoppinsBold;
}
.left-text p{
  color: white;
  text-align: left;
  font-family: Poppinsregular;
  font-size: 18px;
}
.left-text span{
  color: white;
  font-family: Poppinsregular;
  font-size: 18px;
}
.left-text span:hover{
  color: #9e72fc;
  cursor: pointer;
  font-size: 18px;
}

.social-container{
  text-align: left;
  margin-top: 20px;
}
.social-container .social{
  margin-right: 20px;
  color: white !important;
}
.social-container .social:hover{
  color: #9e72fc !important;
}

form{
  width: 100% !important;
}
.contactForm {
  width: 100% !important;
}
#input-field{
  background-color: #0d0d1d !important;
  width: 100% !important;
  color: white !important;
  font-family: Poppinsregular;
}
#text-field{
  background-color: #0d0d1d !important;
  width: 100% !important;
  color: white;
  height: 200px !important;
  font-family: Poppinsregular;
}
.submit-btn{
  width: 100% !important;
  background: url("../src/assets/submit_button.png");
  background-repeat: no-repeat !important;
  background-size: 100% 100% !important;
  border: none !important;
  border-radius: 18px !important;
}
#contact-btn{
  color: white !important;
  font-weight: bold;
}
.contactForm ::placeholder{
  color: white !important;
  font-family: Poppinsregular;
}